import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Header from "./components/Header";
import { ToastContainer } from 'react-toastify';

class App extends Component {
  render() {
    return (
      <div>
        <Header/>
        <Switch>
          <Route exact path='/uploads' component={Home}/>
          <Route exact path='/noke' component={Home}/>
          <Route exact path='/sample01' component={Home}/>
          <Route exact path='/user4' component={Home}/>
          <Route exact path='/user2' component={Home}/>
          <Route exact path='/user3' component={Home}/>
          <Route exact path='/usersi' component={Home}/>
        </Switch>
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(App);
