import * as XLSX from "xlsx";
import {parseSheetData} from "./helpers";

console.log(window.location.pathname);


export function uploadFile(file, fileName) {
  const formData = new FormData();
  const cRoute=window.location.pathname.replace('/','');
  formData.append("userId",cRoute);
  formData.append("name", `${fileName}`); 
  formData.append("file", file);// bug have to put every param befor file
  
  
  return fetch("/upload", {
    method: 'post',
    body: formData
  }).then(rs => rs.json())
}

export function downloadFile(fileName) {
  var cRoute=window.location.pathname.replace('/','');
  return fetch(`/download?user=${cRoute}&name=${fileName}`, {
    method: 'get',
  }).then(rs => rs.blob())
}


export function deleteFile(fileName) {
  var cRoute=window.location.pathname;
  return fetch(`/delete-file?user=${cRoute}&name=${fileName}`, {
    method: 'delete',
  }).then(rs => rs.json())
}

export function getListFiles() {
  var cRoute=window.location.pathname.replace('/','');
  return fetch("/list-files?user="+cRoute, {
    method: 'get',
  }).then(rs => rs.json())
}


export function saveFile({data, sheetName, fileName}) {
  const ws = XLSX.utils.aoa_to_sheet((parseSheetData(data)));
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet 1');
  const wopts = {bookType: 'xlsx', bookSST: false, type: 'array'};
  const wbout = XLSX.write(wb, wopts);
  
  const file = new Blob([wbout], {type: "application/octet-stream"});
  return uploadFile(file, fileName).then(rs => {
    return {
      wb,
      fileName: rs.data.name
    }
  })
}

// connect to pestimate service
// call to cshttp service 
// bug fetch cannot send body to rest 
export async function takeOffFile(fileName) {
  const cRoute=window.location.pathname.replace('/','');
 
  var dir=cRoute+"\\"+fileName;
  //console.log(JSON.stringify(dir))
  
  const resp= await fetch('/pesImportExcel/?name='+cRoute+'&x='+encodeURI(dir), {
    method: 'POST',
   
    headers: { 'Accept': 'application/json',  'Content-Type': 'application/json' },
    body: {},
  }).then(rs => rs.json()).catch(err=>{console.log(err);});
  return resp;
}

//clearTakeOffFile clear takeoff xlsx by file
export async function clearTakeOffFile(fileName) {
  const cRoute=window.location.pathname.replace('/','');
  var dir=fileName; // just file
  //console.log(JSON.stringify(dir))
  const resp= await fetch('/pesClearByFile/?name='+cRoute+'&x='+encodeURI(dir), {
    method: 'POST',
   
    headers: { 'Accept': 'application/json',  'Content-Type': 'application/json' },
    body: {},
  }).then(rs => rs.json()).catch(err=>{console.log(err);});
  return resp;
}

