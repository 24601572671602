import React from 'react';
import PropTypes from 'prop-types';

function Header(props) {
  return (
    <header>
      <img src={'sheetapp-logo.png'}/>
    </header>
  );
}

Header.propTypes = {};
Header.defaultProps = {};

export default Header;
